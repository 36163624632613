import * as React from 'react';
import MDCComponent from '@material/base/component';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Formik, FormikProps } from 'formik';
import './form-wizard.scss';
// import FormikDebug from '../Debug';

export const FormWizardContext: React.Context<FormWizard | {}> = React.createContext({})

/**
 * @class FormWizard
 * TODO: (stefan@cropmates.com) Validation should be per page
 */
class FormWizard extends React.Component<{ initialValues: any; validationSchema: any; onSubmit: any }> {
  static propTypes: any

  private nextHandler: any

  private previousHandler: any

  private submitHandler: any

  public state: {
    page: number
    values: any
    invalidFormControls: Array<any>
    showNextButton: boolean
  }

  constructor(props) {
    super(props)

    this.nextHandler = this.next.bind(this)
    this.previousHandler = this.previous.bind(this)
    this.submitHandler = this.handleSubmit.bind(this)
    this.state = { page: 0, values: props.initialValues, invalidFormControls: [], showNextButton: true }
  }

  static traverseObject(parentKey, obj?, cb?) {
    for (var key in obj) {
      if (!obj.hasOwnProperty(key)) continue
      if (typeof obj[key] !== 'object') cb(parentKey, key)
      FormWizard.traverseObject(obj[key])
    }
  }

  static setFieldValidity(field, valid, error, touched) {
    if (!error) return (field.valid = true)
    if (!touched) return (field.valid = true)
    if (!valid) return
    field.valid = false
  }

  static setFormValidity(errors, touched) {
    if (!errors) return
    if (!touched) return
    if (Object.keys(touched).length === 0) return
    if (Object.keys(errors).length === 0) return

    for (let key in touched) {
      if (typeof touched[key] !== 'object') return

      FormWizard.traverseObject(key, touched[key], (pk, k) => {
        if (!errors[pk]) return
        const fieldName = String(pk)
          .concat('.')
          .concat(k)
        const field = FormWizard.findFormControlByName.call(this, fieldName)

        if (!field) return
        FormWizard.setFieldValidity(field, field.valid, errors[pk][k], true)
      })
    }
  }

  static findFormControlByName(name) {
    for (let control in this) {
      if (!(this[control] instanceof MDCComponent)) continue
      if (!this[control]['input_']) continue
      if (this[control].input_.name !== name) continue
      return this[control]
    }
  }

  next(values) {
    if (!this.props.children) return
    const { children } = this.props
    this.setState((state: { page: number }) => ({
      page: Math.min(state.page + 1, React.Children.count(children) - 1),
      values,
    }))
  }

  previous() {
    this.setState((state: { page: number }) => ({
      page: Math.max(state.page - 1, 0),
    }))
  }

  handleSubmit(values, bag: FormikProps<any>) {
    const { children, onSubmit } = this.props
    const { page } = this.state
    const isLastPage = page == React.Children.count(children) - 1

    if (isLastPage) {
      return onSubmit(values, bag)
    }

    this.next(values)
    bag.setTouched({})
    bag.setSubmitting(false)
  }

  render() {
    const { children } = this.props
    const { page, values } = this.state
    const activePage = React.Children.toArray(children)[page]
    const isLastPage = page == React.Children.count(children) - 1

    return (
      <Formik
        initialValues={values}
        validationSchema={this.props.validationSchema}
        onSubmit={this.submitHandler}
        render={({ isSubmitting, handleSubmit }: FormikProps<any>) => {
          return (
            <div className="mdc-layout-grid__cell--span-12">
              <div className="mdc-layout-grid__inner">
                {/* Active page */}
                <div className="mdc-layout-grid__cell--span-12">
                  <FormWizardContext.Provider value={this}>{activePage}</FormWizardContext.Provider>
                </div>

                {/* Navigation */}
                <div className="mdc-layout-grid__cell--span-12">
                  <div className="mdc-layout-grid__inner">
                    {/* Back button */}
                    {page > 0 && (
                      <div className="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--align-left">
                        <button type="button" className="mdc-button" onClick={this.previousHandler}>
                          <FormattedMessage id="signup.back" defaultMessage="Zurück" />
                        </button>
                      </div>
                    )}

                    {/* Next button */}
                    {!isLastPage && this.state.showNextButton && (
                      <div className="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--align-right">
                        <button type="button" className="mdc-button mdc-button--raised cm-button--primary" onClick={this.nextHandler}>
                          <FormattedMessage id="signup.next" defaultMessage="Weiter" />
                        </button>
                      </div>
                    )}

                    {/* Submit button */}
                    {isLastPage && (
                      <div className="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--align-right">
                        <button
                          id="cta-signup"
                          className="mdc-button mdc-button--raised cm-button--primary"
                          type="submit"
                          onClick={handleSubmit as React.MouseEventHandler<any>}
                          disabled={isSubmitting}
                        >
                          <FormattedMessage id="signup.cta" defaultMessage="Account erstellen" />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* <FormikDebug /> */}
            </div>
          )
        }}
      />
    )
  }
}

FormWizard.propTypes = {
  initialValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node,
  validationSchema: PropTypes.func.isRequired,
}

export default FormWizard
